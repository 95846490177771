// src/wrap-with-provider.tsx
import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

interface WrapRootElementProps {
  element: ReactNode;
}

export const wrapRootElement = ({ element }: WrapRootElementProps) => (
  <QueryClientProvider client={queryClient}>
    {element}
  </QueryClientProvider>
);
