exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-favorites-tsx": () => import("./../../../src/pages/favorites.tsx" /* webpackChunkName: "component---src-pages-favorites-tsx" */),
  "component---src-pages-payment-response-tsx": () => import("./../../../src/pages/payment-response.tsx" /* webpackChunkName: "component---src-pages-payment-response-tsx" */),
  "component---src-pages-tpv-return-tsx": () => import("./../../../src/pages/tpv-return.tsx" /* webpackChunkName: "component---src-pages-tpv-return-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/articleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-checkout-template-tsx": () => import("./../../../src/templates/checkoutTemplate.tsx" /* webpackChunkName: "component---src-templates-checkout-template-tsx" */),
  "component---src-templates-confirmation-template-tsx": () => import("./../../../src/templates/confirmationTemplate.tsx" /* webpackChunkName: "component---src-templates-confirmation-template-tsx" */),
  "component---src-templates-contacte-template-tsx": () => import("./../../../src/templates/contacteTemplate.tsx" /* webpackChunkName: "component---src-templates-contacte-template-tsx" */),
  "component---src-templates-faqs-template-tsx": () => import("./../../../src/templates/faqsTemplate.tsx" /* webpackChunkName: "component---src-templates-faqs-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/homeTemplate.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-homebooking-template-tsx": () => import("./../../../src/templates/homebookingTemplate.tsx" /* webpackChunkName: "component---src-templates-homebooking-template-tsx" */),
  "component---src-templates-legal-template-tsx": () => import("./../../../src/templates/legalTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-template-tsx" */),
  "component---src-templates-nosaltres-template-tsx": () => import("./../../../src/templates/nosaltresTemplate.tsx" /* webpackChunkName: "component---src-templates-nosaltres-template-tsx" */),
  "component---src-templates-payment-failure-template-tsx": () => import("./../../../src/templates/PaymentFailureTemplate.tsx" /* webpackChunkName: "component---src-templates-payment-failure-template-tsx" */),
  "component---src-templates-payment-success-template-tsx": () => import("./../../../src/templates/PaymentSuccessTemplate.tsx" /* webpackChunkName: "component---src-templates-payment-success-template-tsx" */),
  "component---src-templates-propietari-template-tsx": () => import("./../../../src/templates/propietariTemplate.tsx" /* webpackChunkName: "component---src-templates-propietari-template-tsx" */),
  "component---src-templates-search-page-template-tsx": () => import("./../../../src/templates/SearchPageTemplate.tsx" /* webpackChunkName: "component---src-templates-search-page-template-tsx" */),
  "component---src-templates-serveis-template-tsx": () => import("./../../../src/templates/serveisTemplate.tsx" /* webpackChunkName: "component---src-templates-serveis-template-tsx" */),
  "component---src-templates-success-case-template-tsx": () => import("./../../../src/templates/successCaseTemplate.tsx" /* webpackChunkName: "component---src-templates-success-case-template-tsx" */),
  "component---src-templates-touristic-rental-apartment-template-tsx": () => import("./../../../src/templates/touristicRentalApartmentTemplate.tsx" /* webpackChunkName: "component---src-templates-touristic-rental-apartment-template-tsx" */)
}

